<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card class="primary--border" outlined>
          <v-card-title class="title">
            <span>Live Classes</span>
            <v-spacer></v-spacer>
            <add-button
              permission="live-class-create"
              @action="fetZoomKeys(), (form.dialog = true)"
              >New Class
            </add-button>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="footerProps"
            :loading="form.loading"
            :server-items-length="form.items.meta.total"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ ++index }}</td>
                <td class="text-xs-left">{{ item.subject.name }}</td>
                <td class="text-xs-left">{{ item.grade.name }}</td>
                <td class="text-xs-left">
                  <v-chip
                    class="mr-1"
                    label
                    small
                    v-for="(section, key) in item.sections"
                    :key="key"
                  >
                    {{ section.name }}
                  </v-chip>
                </td>
                <td class="text-xs-left">
                  <!--{{item.zoom}}-->
                  {{ item.name }} <br />
                  <small v-if="item.zoom" style="color:#999;"
                    >{{ item.zoom.user_name.toUpperCase() }} ({{
                      item.zoom.meeting_id
                    }})
                  </small>
                </td>
                <td class="text-xs-left">
                  <template v-if="item.day.length < 7">
                    <v-chip
                      v-for="(val, k) in item.day"
                      :key="k"
                      class="mr-1 mb-1 mt-1"
                      label
                      small
                    >
                      {{ val.toUpperCase() }}
                    </v-chip>
                  </template>
                  <v-chip label small v-else>EVERYDAY</v-chip>
                  @
                  <v-chip label small class="primary">
                    {{ item.start_time }} - {{ item.end_time }}
                  </v-chip>
                </td>
                <td class="text-left">
                  <v-chip
                    class="mt-1"
                    :color="
                      item.zoom != null && item.status === 'Live'
                        ? 'red'
                        : '#ccc'
                    "
                    @click="item.zoom != null ? startVideo(item, 'zoom') : ''"
                    label
                    small
                    text-color="white"
                  >
                    <span
                      class=""
                      style="display: inline-block;margin-bottom: 3px;"
                      v-if="$auth.loggedInRole().slug === 'student'"
                      >Join Class</span
                    >
                    <span v-else>Host Class</span>
                  </v-chip>
                  &nbsp;
                  <v-chip
                    v-if="item.zoom === null"
                    @click="item.status !== 'Live' ? '' : startVideo(item)"
                    label
                    small
                    class="mt-1 mb-1"
                    :color="item.status === 'Live' ? 'red' : '#ccc'"
                    text-color="white"
                  >
                    <v-icon left>video_call</v-icon>
                    {{ item.status }}
                  </v-chip>
                  <br />
                </td>
                <td class="text-right">
                  <edit-button
                    permission="live-class-update"
                    @agree="fetZoomKeys(), form.edit(item)"
                  />
                  <!--<edit-button icon="vpn_key" color="success" permission="live-class-update" label="Set Keys" @agree="form.edit(item)"/>-->
                  <delete-button
                    permission="live-class-delete"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search for "{{ search }}" found no results.
            </v-alert>
          </v-data-table>
          <v-card-actions>
            <v-spacer />
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-dialog v-model="form.dialog" scrollable persistent max-width="500px">
        <v-card outlined class="primary--border">
          <v-card-title class="primary white--text">
            <span class="title">Add/Update</span>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              @submit.prevent="store"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="store"
              lazy-validation
            >
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12 class="mt-2">
                    <v-text-field
                      label="Name"
                      autocomplete="off"
                      required
                      class="pa-0"
                      v-model="form.name"
                      name="name"
                      outlined
                      dense
                      :error-messages="form.errors.get('name')"
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-select
                      :items="grades"
                      :disabled="!grades.length"
                      required
                      label="Grade*"
                      v-model="form.grade_id"
                      :error-messages="form.errors.get('grade_id')"
                      name="grade"
                      outlined
                      dense
                    />
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-multi-select-field
                      v-model="form.section_id"
                      :item-list="sections"
                    ></v-multi-select-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      :items="subjects"
                      :disabled="!subjects.length"
                      required
                      label="Subject*"
                      v-model="form.subject_id"
                      :error-messages="form.errors.get('subject_id')"
                      name="subject"
                      outlined
                      dense
                    />
                  </v-flex>

                  <v-flex xs12>
                    <v-multi-select-field
                      v-model="form.day"
                      :item-list="selectDaysExceptAll"
                    ></v-multi-select-field>
                  </v-flex>

                  <v-flex xs12 sm6>
                    <v-menu
                      ref="menu"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="form.start_time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          v-model="form.start_time"
                          label="Start Time"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        format="24hr"
                        v-if="menu2"
                        v-model="form.start_time"
                        full-width
                        @click:minute="$refs.menu.save(form.start_time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-menu
                      ref="menu2"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="form.end_time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          v-model="form.end_time"
                          label="End Time"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        format="24hr"
                        v-if="menu1"
                        v-model="form.end_time"
                        full-width
                        @click:minute="$refs.menu2.save(form.end_time)"
                      ></v-time-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex xs12>
                    <v-select
                      :items="zoom_keys"
                      :disabled="!grades.length"
                      required
                      label="Select Conference"
                      v-model="form.zoom_key_id"
                      :error-messages="form.errors.get('grade_id')"
                      name="grade"
                      outlined
                      dense
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              text
              @click="(form.dialog = false), form.reset()"
              >Close</v-btn
            >
            <v-btn color="success" text :loading="form.loading" @click="store"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>
<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
import { validateDate, queryStringToJSON } from "../../../library/helpers";

export default {
  mixins: [Mixins],
  // },
  props: {
    schoolId: {
      required: false,
    },
  },
  data: () => ({
    menu2: "",
    menu1: "",
    form: new Form(
      {
        dialog: false,
        name: "",
        day: "",
        days: "",
        subject_id: "",
        start_time: "",
        end_time: "",
        grade_id: "",
        section_id: "",
        status: "live",
        zoom_key_id: "",
      },
      "/api/conference"
    ),
    zoom_keys: [],
    search: null,
    headers: [
      { text: "SN", align: "left", value: "id", width: 80, sortable: false },
      { text: "Subject", align: "left", value: "subject", sortable: false },
      {
        text: "Grade",
        align: "left",
        value: "grade",
        sortable: false,
        width: 120,
      },
      { text: "Section", align: "left", value: "section", sortable: false },
      { text: "Name", align: "left", value: "name", sortable: false },
      {
        text: "Day/Time",
        align: "left",
        value: "date",
        sortable: false,
        width: 400,
      },
      {
        text: "Status",
        align: "left",
        value: "name",
        sortable: false,
        width: 100,
      },
      { text: "Action", align: "right", sortable: false, width: 130 },
    ],
    dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
    grades: [],
    sections: [],
    subjects: [],
    statuses: [
      {
        text: "Live",
        value: "live",
      },
    ],
    days: [
      { text: "Sunday", value: "sunday" },
      { text: "Monday", value: "monday" },
      { text: "Tuesday", value: "tuesday" },
      { text: "Wednesday", value: "wednesday" },
      { text: "Thursday", value: "thursday" },
      { text: "Friday", value: "friday" },
      { text: "Saturday", value: "saturday" },
    ],
    leave_data: "",
  }),
  watch: {
    pagination: function() {
      this.get();
    },
    "form.grade_id": function(value) {
      let grade = this.grades.find((grade) => grade.value === value);
      if (grade) this.sections = grade.sections;
    },
    "form.section_id": function(value) {
      this.getSubjects();
    },
  },
  computed: {
    selectDaysExceptAll() {
      return this.days.filter(function(day) {
        if (day.value !== "all") return day;
      });
    },
  },

  mounted() {
    this.getGrades();
    this.leave_data = queryStringToJSON();
    console.log(this.leave_data,'leave_data');
    if (this.$route.query.flag != undefined) {
      if (this.$route.query.flag == 1) {
        this.onStudentLeave();
      } else {
        this.onTeacherLeave();
      }
    }
  },

  methods: {
    onStudentLeave() {
      this.$rest
        .post("/api/zoom-attend", {
          data:this.leave_data,
        })
        .then((res) => {})
        .catch((e) => {});
    },
    onTeacherLeave() {
      console.log(this.leave_data)
      this.$rest
        .post("/api/conference-host", {
          ...this.leave_data,
        })
        .then((res) => {})
        .catch((e) => {});
    },
    get(params) {
      let extraParams = this.schoolId ? `&schoolId=${this.schoolId}` : "";
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, this.queryString(extraParams)).then(({ data }) => {
        this.form.items.data = data.data.map((item) => {
          item.section_id = item.section_ids;
          return item;
        });

        this.pagination.totalItems = data.meta.total;
      });
    },

    store() {
      if (this.form.day.length === 7) {
        this.form.days = "everyday";
      } else {
        this.form.days = this.form.day ? this.form.day.join(",") : null;
      }
      this.form.fireFetch = false;
      this.form.store().then(() => {
        this.get();
      });
    },

    getGrades() {
      this.$rest
        .get("/api/grades?rowsPerPage=25&sortBy=rank&descending=false")
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return {
              id: item.id,
              text: item.name,
              value: item.id,
              sections: item.sections.map((section) => {
                return {
                  id: section.id,
                  grade_id: item.id,
                  text: section.name,
                  value: section.id,
                };
              }),
            };
          });
        });
    },

    getSubjects(section) {
      if (this.form.grade_id)
        this.$rest
          .get(
            `/api/subject?grade=${this.form.grade_id}&rowsPerPage=25&sortBy=name&descending=false`
          )
          .then(({ data }) => {
            this.subjects = data.data.map((subject) => {
              return { id: subject.id, text: subject.name, value: subject.id };
            });
          });
    },

    fetZoomKeys() {
      this.$rest.get("/api/conference/zoom/keys").then(({ data }) => {
        this.zoom_keys = data.data.map((item) => {
          return {
            value: item.id,
            text: item.user_name + `(${item.meeting_id})`,
          };
        });
      });
    },

    startVideo(item, type = "") {
      if (type === "zoom") {
        this.$router.push({
          name: "zoom",
          params: {
            meetingId: item.code,
          },
        });
      } else {
        this.$router.push({
          name: "live-class-detail",
          params: {
            classId: item.code,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
